import { useState, useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db, auth } from "./firebaseConfig";

export function OrdersTable() {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.currentUser) {
      const q = query(
        collection(db, "orders"),
        where("userId", "==", auth.currentUser.uid)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const ordersData: any[] = [];
        querySnapshot.forEach((doc) => {
          ordersData.push({ id: doc.id, ...doc.data() });
        });
        setOrders(ordersData);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, []);

  if (loading) return <div>טוען...</div>;

  return (
    <div>
      <h2>הזמנות שלי</h2>
      <table>
        <thead>
          <tr>
            <th>מספר הזמנה</th>
            <th>תאריך</th>
            <th>סטטוס</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.orderNumber}</td>
              <td>{order.date?.toDate().toLocaleDateString()}</td>
              <td>{order.status}</td>
              <td>{/* כפתורים נוספים */}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
