import { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth } from "./firebaseConfig";
import { v4 as uuidv4 } from "uuid";

export function TokenManager() {
  const [token, setToken] = useState("");
  const [savedToken, setSavedToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        if (auth.currentUser) {
          const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
          if (userDoc.exists()) {
            setSavedToken(userDoc.data().token || "");
          }
        }
      } catch (err) {
        console.error("Failed to fetch token:", err);
        setError("שגיאה בטעינת הטוקן");
      }
    };
    fetchToken();
  }, []);

  const generateNewToken = async () => {
    try {
      setIsLoading(true);
      const newToken = uuidv4(); // יצירת טוקן ייחודי אקראי

      if (auth.currentUser) {
        await updateDoc(doc(db, "users", auth.currentUser.uid), {
          token: newToken,
          tokenGeneratedAt: new Date(), // שמירת זמן יצירת הטוקן
        });
        setSavedToken(newToken);
        setToken("");
        alert("טוקן חדש נוצר בהצלחה!");
      }
    } catch (err) {
      console.error("Failed to generate token:", err);
      setError("שגיאה ביצירת טוקן");
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualTokenSave = async () => {
    if (!token.trim()) {
      setError("נא להזין טוקן חוקי");
      return;
    }

    try {
      setIsLoading(true);
      if (auth.currentUser) {
        await updateDoc(doc(db, "users", auth.currentUser.uid), {
          token: token.trim(),
          tokenGeneratedAt: new Date(),
        });
        setSavedToken(token.trim());
        setToken("");
        alert("טוקן נשמר בהצלחה!");
      }
    } catch (err) {
      console.error("Failed to save token:", err);
      setError("שגיאה בשמירת הטוקן");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="token-manager">
      <h2>ניהול טוקן</h2>

      <div className="token-display">
        <p>
          <strong>טוקן נוכחי:</strong>
        </p>
        <div className="token-value">
          {savedToken || <span className="no-token">לא הוגדר</span>}
        </div>
        {savedToken && (
          <button
            onClick={() => {
              navigator.clipboard.writeText(savedToken);
              alert("טוקן הועתק!");
            }}
            disabled={isLoading}
          >
            העתק טוקן
          </button>
        )}
      </div>

      <div className="token-generation">
        <button onClick={generateNewToken} disabled={isLoading}>
          {isLoading ? "מייצר..." : "צור טוקן חדש"}
        </button>
      </div>

      <div className="token-input">
        <h3>או הזן טוקן ידנית:</h3>
        <input
          type="text"
          value={token}
          onChange={(e) => {
            setToken(e.target.value);
            setError("");
          }}
          placeholder="הזן טוקן מותאם אישית"
          disabled={isLoading}
        />
        <button
          onClick={handleManualTokenSave}
          disabled={isLoading || !token.trim()}
        >
          שמור טוקן
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
}
