import { useEffect } from "react";
import { auth } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import { OrdersTable } from "./OrdersTable";
import { TokenManager } from "./TokenManager";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

export function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (!userDoc.exists() || !userDoc.data().paid) {
          navigate("/payment");
        }
      } else {
        navigate("/login");
      }
    };

    checkPaymentStatus();
  }, [navigate]);

  if (!auth.currentUser) {
    return null; // או טעינה
  }

  return (
    <div className="dashboard-container">
      <header>
        <h1>ברוך הבא, {auth.currentUser.email}</h1>
        <button onClick={() => auth.signOut()}>התנתק</button>
      </header>

      <section className="token-section">
        <TokenManager />
      </section>

      <section className="orders-section">
        <h2>ההזמנות שלי</h2>
        <OrdersTable />
      </section>
    </div>
  );
}
