import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // בדיקה אם המשתמש שילם
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      if (userDoc.exists() && userDoc.data().paid) {
        navigate("/dashboard");
      } else {
        await auth.signOut();
        setError("עליך להשלים תשלום לפני הכניסה");
      }
    } catch (err) {
      setError("אימייל או סיסמה לא תקינים");
      console.error("Error logging in:", err);
    }
  };

  return (
    <div className="login-container">
      <h2>התחברות</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleLogin}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="אימייל"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="סיסמה"
          required
        />
        <button type="submit">התחבר</button>
      </form>
      <p>
        עדיין לא רשום? <a href="/signup">צור חשבון חדש</a>
      </p>
    </div>
  );
}
