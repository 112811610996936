import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

export function Payment() {
  const navigate = useNavigate();

  const handlePayment = async () => {
    if (auth.currentUser) {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        paid: true,
      });
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <h2>תשלום</h2>
      <p>כאן יהיה טופס תשלום אמיתי. בשלב זה לחץ על הכפתור כדי לדמות תשלום.</p>
      <button onClick={handlePayment}>שילמתי</button>
    </div>
  );
}
